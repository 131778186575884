.custom-scene {
    position: relative;
    width: 300px; 
    height: 300px; 
    transform-style: preserve-3d; 
  }
  
  .custom-cube {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d; 
  }
  
  .face {
    position: absolute;
    width: 300px; 
    height: 300px; 
    background: rgba(76, 0, 130, 0.1); 
    border: 1px solid rgba(47, 79, 79, 0.1);
    transform-style: preserve-3d; 
  }
  
  .front {
    transform: rotateY(0deg) translateZ(150px); 
  }
  
  .back {
    transform: rotateY(180deg) translateZ(150px);
  }
  
  .left {
    transform: rotateY(-90deg) translateZ(150px);
  }
  
  .right {
    transform: rotateY(90deg) translateZ(150px);
  }
  
  .top {
    transform: rotateX(90deg) translateZ(150px);
  }
  
  .bottom {
    transform: rotateX(-90deg) translateZ(150px);
  }
  
  .custom-grid {
    display: grid; 
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    height: 100%; 
    overflow: hidden; 
  }
  
  
  .custom-grid span {
    width: 30px; /* Width of each grid item */
    height: 30px; /* Height of each grid item */
    background: rgba(0, 0, 0, 0.6); /* Background color */
    border: 0.1px solid rgba(255, 255, 255, 0.6); /* Border color */
    transform-style: preserve-3d; /* Preserve 3D style */
  }
  
  .custom-grid span.active {
    background: #fff; /* Active state color */
    z-index: 10000; /* Bring active item to the front */
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.6)) blur(2px); /* Add shadow */
  }


  @media (max-width: 768px) {
    .custom-scene {
      position: relative;
      left: 50%;
    }
    
    .face {
      position: absolute;
      width: 200px; 
      height: 200px; 
      background: rgba(76, 0, 130, 0.1); 
      border: 1px solid rgba(47, 79, 79, 0.1);
      transform-style: preserve-3d; 
    }

    .front {
      transform: rotateY(0deg) translateZ(100px);
    }
  
    .back {
      transform: rotateY(180deg) translateZ(100px);
    }
  
    .left {
      transform: rotateY(-90deg) translateZ(100px);
    }
  
    .right {
      transform: rotateY(90deg) translateZ(100px);
    }
  
    .top {
      transform: rotateX(90deg) translateZ(100px);
    }
  
    .bottom {
      transform: rotateX(-90deg) translateZ(100px);
    }
  }