.carousel{
    background-color: #1e1e1e;
    height: 500vh;
}
.contentContainer{
    height: 100vh;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.images{
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: 1fr;
    grid-gap: 1vw;
}