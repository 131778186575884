@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');


html {
  scroll-behavior: smooth;
  background-color:#F8F8FF;
}

/* Custom Font */
.font-jomhuria {
  font-family: 'Jomhuria', cursive;
}
body {
  overflow: -moz-scrollbars-none !important;
  -ms-overflow-style: none !important;  /* IE and Edge */
  scrollbar-width: none !important;     /* Firefox */
}

body::-webkit-scrollbar {
  display: none !important;  /* Chrome, Safari, and Opera */
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.font-jua {
  font-family: 'Jua', sans-serif;
}


